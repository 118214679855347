<template>
    <div>
        <div
            class="mcard__row"
            :class="is_opened?'':'hidden_row'"
        >
            <span
                class="pa-2 mcard__row_title"
            >
                <v-icon
                    v-if="item.parent > 0"
                    small
                >
                    mdi-menu-open
                </v-icon>
                {{ item.title }}
            </span>
            <span
                class="mcard__row_kind"
                :data-srt="item.sort"
            >
                {{ types[item.type] }}
            </span>
            <span
                class="mcard__row_value"
            >
                {{ item.value }}
            </span>
            <v-btn-toggle
                class="pa-2 mcard__row_unpack"
            >
                <v-btn
                    v-if="((item.parent == 0) || (item.parent == null)) && (item.subitems.length > 0) && (!item.opened)"
                    @click="openItem(item)"
                    color="primary"
                    small
                >
                    <v-icon
                        small
                        title="Развернуть подпункты"
                        color="white"
                    >
                        mdi-dots-horizontal-circle
                    </v-icon>
                </v-btn>
                <v-btn
                    v-if="((item.parent == 0) || (item.parent == null)) && (item.subitems.length > 0) && (item.opened)"
                    @click="closeItem(item)"
                    color="primary"
                    small
                >
                    <v-icon
                        small
                        title="Свернуть подпункты"
                        color="white"
                    >
                        mdi-dots-horizontal-circle-outline
                    </v-icon>
                </v-btn>
            </v-btn-toggle>
            <v-btn-toggle
                class="pa-2 mcard__row_actions"
            >
                <v-btn
                    color="primary"
                    v-show="item.sort > 1"
                    small
                    @click="sortUp(item)"
                >
                    <v-icon
                        small
                        title="Переместить выше"
                        color="white"
                    >
                        mdi-menu-up-outline
                    </v-icon>
                </v-btn>
                <v-btn
                    color="primary"
                    v-show="item.sort < max_sort"
                    small
                    @click="sortDn(item)"
                >
                    <v-icon
                        small
                        title="Переместить ниже"
                        color="white"
                    >
                        mdi-menu-down-outline
                    </v-icon>
                </v-btn>
                <v-btn
                    color="error"
                    small
                    @click="removeItem(item)"
                >
                    <v-icon
                        small
                        title="Удалить пункт"
                        color="white"
                    >
                        mdi-delete
                    </v-icon>
                </v-btn>
                <v-btn
                    color="primary"
                    small
                    @click="editItem(item)"
                >
                    <v-icon
                        small
                        title="Изменить пункт"
                        color="white"
                    >
                        mdi-pencil
                    </v-icon>
                </v-btn>
                <v-btn
                    v-show="(item.parent == 0) || (item.parent == null)"
                    color="success"
                    small
                    @click="addSubItem(item)"
                >
                    <v-icon
                        small
                        title="Добавить подпункт"
                        color="white"
                    >
                        mdi-text-box-plus
                    </v-icon>
                </v-btn>
            </v-btn-toggle>
        </div>
        <MenuItem
            v-for="sub in item.subitems"
            :key="sub.id"
            v-bind:item="sub"
            v-bind:is_opened="item.opened"
            v-bind:types="types"
            v-bind:max_sort="getMaxSort(item)"
            @sortUpEvent="sortUp"
            @sortDnEvent="sortDn"
            @removeItemEvent="removeItem"
            @editItemEvent="editItem"
            @addSubItemEvent="addSubItem"
        >
        </MenuItem>
    </div>
</template>

<script>
export default {
    name: 'MenuItem',
    props: {
        item: {
            type: Object,
            required: true,
        },
        is_opened: {
            type: Boolean,
            required: true,
        },
        max_sort: {
            type: Number,
            required: true
        },
        types: {
            type: Object,
            required: true,
        },
    },
    methods: {
        openItem (item) {
            item.opened = true;
        },
        closeItem (item) {
            item.opened = false;
        },
        sortUp (item) {
            this.$emit('sortUpEvent', item);
        },
        sortDn (item) {
            this.$emit('sortDnEvent', item);
        },
        removeItem (item) {
            this.$emit('removeItemEvent', item);
        },
        editItem (item) {
            this.$emit('editItemEvent', item);
        },
        addSubItem (item) {
            this.$emit('addSubItemEvent', item);
        },
        getMaxSort (item) {
            let v = 0;
            for(let i = 0; i < item.subitems.length; i++) {
                if (item.subitems[i].sort > v) {
                    v = item.subitems[i].sort;
                }
            }
            return v;
        },
    },
};
</script>
<style lang="scss" scoped>
    .mcard {

        &__actions_row {
            margin: 0 0 0 10px;
            padding: 10px 10px 10px 0;
        }

        &__action_button {
            margin-right: 10px;
        }

        &__rows {
            display: flex;
            flex-direction: column;
        }

        &__row {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
        }

        &__row_actions {
            background: transparent !important;
            min-width: 166px;
            display: flex;
            flex-direction: row;
            justify-content: flex-end;

            .v-btn {
                padding: 0 3px;
                min-width: 30px;
            }
        }

        &__row_unpack {
            background: transparent !important;
            min-width: 40px;
            width: 40px;

            .v-btn {
                padding: 0 3px;
                min-width: 30px;
            }
        }

        &__row:hover {
            background: #eee;
        }

        &__row_title {
            line-height: 28px;
            min-width: 35vw;
        }

        &__row_kind {
            line-height: 44px;
            min-width: 13vw;
            max-width: 13vw;
            font-size: 14px;
            color: #999;
        }

        &__row_value {
            line-height: 44px;
            width: 15vw;
            max-width: 15vw;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
    }

    .hidden_row {
        display: none;
    }
</style>