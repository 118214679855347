<template>
    <div>
        <v-dialog
            v-model="dialogRemove"
            max-width="500px"
        >
            <v-card>
                <v-card-title>
                    <span class="text-h5">Удаление пункта меню</span>
                </v-card-title>

                <v-card-text>Вы действительно хотите выбранный пункт меню?</v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="blue darken-1"
                        text
                        @click="closeRemove"
                    >
                        Отмена
                    </v-btn>
                    <v-btn
                        color="blue darken-1"
                        text
                        @click="doRemoveItem(itemToRemove, itemToRemoveParent)"
                    >
                        Удалить
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog
            v-model="dialogEdit"
            max-width="500px"
        >
            <v-card>
                <v-card-title>
                    <span class="text-h5">{{ formTitle }}</span>
                </v-card-title>

                <v-card color="basil" flat>
                    <v-card-text>
                        <v-text-field label="Заголовок" v-model="editingItem.title"></v-text-field>
                    </v-card-text>
                </v-card>
                <v-card color="basil" flat>
                    <v-card-text>
                        <v-select label="Тип" v-model="editingItem.type" :items="types2" :menu-props="{ maxHeight: '400' }" persistent-hint item-text="name" item-value="id"></v-select>
                    </v-card-text>
                </v-card>
                <v-card
                    v-show="needValues[editingItem.type] && (editingItem.type != 8)"
                    color="basil"
                    flat
                >
                    <v-card-text>
                        <v-text-field label="Значение" v-model="editingItem.value"></v-text-field>
                    </v-card-text>
                </v-card>
                <v-card
                    v-show="needValues[editingItem.type] && (editingItem.type == 8)"
                    color="basil"
                    flat
                >
                    <v-card-text>
                        <v-select label="Категория" v-model="editingItem.value" :items="object_cats" :menu-props="{ maxHeight: '400' }" persistent-hint item-text="name" item-value="id"></v-select>
                    </v-card-text>
                </v-card>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="blue darken-1"
                        text
                        @click="closeEdit"
                    >
                        Отмена
                    </v-btn>
                    <v-btn
                        color="blue darken-1"
                        text
                        @click="save"
                    >
                        Сохранить
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-card
            class="mcard"
        >
            <v-row
                class="mcard__actions_row"
            >
                <v-btn
                    @click="addToTop"
                    color="success"
                    class="mcard__action_button"
                >
                    Добавить пункт меню
                </v-btn>
                <v-btn
                    @click="openAll"
                    color="primary"
                    class="mcard__action_button"
                >
                    Развернуть все
                </v-btn>
                <v-btn
                    @click="closeAll"
                    color="primary"
                    class="mcard__action_button"
                >
                    Свернуть все
                </v-btn>
            </v-row>
            <v-divider></v-divider>
            <div
                class="mcard__rows"
            >
                <MenuItem
                    v-for="item in items"
                    :key="item.id"
                    v-bind:item="item"
                    v-bind:is_opened="true"
                    v-bind:types="types"
                    v-bind:max_sort="getMaxSort()"
                    @sortUpEvent="sortUp"
                    @sortDnEvent="sortDn"
                    @removeItemEvent="removeItem"
                    @editItemEvent="editItem"
                    @addSubItemEvent="addSubItem"
                >
                </MenuItem>
            </div>
        </v-card>
    </div>
</template> 

<script>
import MenuItem from '@c/MenuItem.vue';

export default {
    name: 'MainMenu',
    components: {
        MenuItem,
    },
    data: () => ({
        loading: false,
        dialogRemove: false,
        itemToRemove: -1,
        itemToRemoveParent: -1,
        dialogEdit: false,
        maxId: 0,
        editingItem: {
            id: 0,
            title: '',
            parent: 0,
            sort: 0,
            type: 1,
            value: '',
            subitems: [],
            opened: false,
        },
        types2: [],
        types: {},
        needValues: [],
        items: [],
        object_cats: [],
    }),
    async mounted() {
        this.getTypes();
        this.getCategories();
        this.getList();
    },
    computed: {
        formTitle () {
            return this.editingItem.id === -1 ? 'Новый пункт меню' : 'Редактирование пункта меню #' + this.editingItem.id;
        },
    },
    methods: {
        async getTypes () {
            this.loading = true;
            try {
                const { list } = await this.$api('/admin/menu_types');

                this.types = {};
                this.types2 = [];

                list.forEach((singleNew, index) => {
                    this.types[list[index].id] = list[index].name;
                    this.types2.push({ id: list[index].id, name: list[index].name});
                    this.needValues[list[index].id] = list[index].needValue;
                });
            } catch (e) {
                this.$error(e.message);
            }
        },
        async getCategories () {
            try {
                const { list } = await this.$api('/admin/object_categories/full');

                this.object_cats = list;
                /*
                list.forEach((singleNew, index) => {
                    this.object_cats.push({ id: list[index].id, name: list[index].name});
                }); */
            } catch (e) {
                this.$error(e.message);
            }
        },
        async getList () {
            try {
                const { list } = await this.$api('/admin/menu_items');
                this.items = [];
                let i = 0;
                list.forEach((singleNew, index) => {
                    let item = {
                        id: 0,
                        title: '',
                        parent: 0,
                        sort: 0,
                        type: 0,
                        value: '',
                        subitems: [],
                        opened: false,
                    };
                    item.id = list[index].id;
                    item.title = list[index].title;
                    item.parent = list[index].parent;
                    item.sort = parseInt(list[index].sort);
                    item.type = parseInt(list[index].type);
                    item.value = list[index].value;
                    if ((item.parent === 0) || (item.parent === null)) {
                        this.items.push(item);
                    } else {
                        for (i = 0; i < this.items.length; i++) {
                            if (parseInt(this.items[i].id) === parseInt(item.parent)) {
                                this.items[i].subitems.push(item);
                                break;
                            }
                        }
                    }
                });
            } catch (e) {
                this.$error(e.message);
            }
            this.loading = false;
        },
        closeRemove () {
            this.dialogRemove = false;
            this.$nextTick(() => {
                this.itemToRemove = -1;
                this.itemToRemoveParent = -1;
            });
        },
        removeItem (item) {
            if (item.subitems.length > 0) {
                this.$error('У выбранного пункта меню есть активные подпункты. Он не может быть удалён.');
                return;
            }
            this.itemToRemove = item.id;
            this.dialogRemove = true;
            this.itemToRemoveParent = item.parent;
        },
        async doRemoveItem (itemToRemove, itemToRemoveParent) {
            if (this.itemToRemove === -1) {
                this.dialogRemove = false;
                return;
            }
            //query to api
            try {
                let r = await this.$api('/admin/menu_items', {
                    method: 'DELETE',
                    body: {
                        id: this.itemToRemove,
                    },
                });
                if (!r.error) {
                    this.getList();
                }
            } catch (e) {
                this.$error(e.message);
            }
            this.dialogRemove = false;
            let i = 0;
            if (itemToRemoveParent === 0) {
                for (i = 0; i < this.items.length; i++) {
                    if (this.items[i].id === itemToRemove) {
                        this.items.splice(i, 1);
                        break;
                    }
                }
            } else {
                let parent_position = 0;
                for (i = 0; i < this.items.length; i++) {
                    if (this.items[i].id === itemToRemoveParent) {
                        parent_position = i;
                        break;
                    }
                }
                for (i = 0; i < this.items[parent_position].subitems.length; i++) {
                    if (this.items[parent_position].subitems[i].id === itemToRemove) {
                        this.items[parent_position].subitems.splice(i, 1);
                        break;
                    }
                }
            }
            this.itemToRemove = -1;
            this.itemToRemoveParent = -1;
        },
        editItem (item) {
            this.editingItem = item;
            this.dialogEdit = true;
        },
        addToTop () {
            this.editingItem = {
                id: -1,
                title: '',
                parent: 0,
                sort: 0,
                type: '',
                value: '',
                subitems: [],
                opened: false,
            };
            this.dialogEdit = true;
        },
        addSubItem (item) {
            this.editingItem = {
                id: -1,
                title: '',
                parent: item.id,
                sort: 0,
                type: '',
                value: '',
                subitems: [],
                opened: false,
            };
            this.dialogEdit = true;
        },
        closeEdit () {
            this.dialogEdit = false;
        },
        async save () {
            let i = 0;
            if (this.editingItem.id == -1) {
                this.maxId++;
                this.editingItem.id = this.maxId;
                if (this.editingItem.parent == 0) {
                    this.editingItem.sort = this.items.length + 1;
                    this.items.push(this.editingItem);
                } else {
                    for (i = 0; i < this.items.length; i++) {
                        if (this.items[i].id == this.editingItem.parent) {
                            break;
                        }
                    }
                    this.editingItem.sort = this.items[i].subitems.length + 1;
                    this.items[i].subitems.push(this.editingItem);
                }
                //new item adding query to api
                try {
                    let r = await this.$api('/admin/menu_items', {
                        method: 'POST',
                        body: {
                            title: this.editingItem.title,
                            parent: this.editingItem.parent,
                            sort: this.editingItem.sort,
                            type: this.editingItem.type,
                            value: this.editingItem.value
                        },
                    });
                    if (!r.error) {
                        this.getList();
                        this.closeEdit();
                    }
                } catch (e) {
                    this.$error(e.message);
                }
            } else {
                try {
                    let r = await this.$api('/admin/menu_items', {
                        method: 'PUT',
                        body: {
                            id: this.editingItem.id,
                            title: this.editingItem.title,
                            parent: this.editingItem.parent,
                            sort: this.editingItem.sort,
                            type: this.editingItem.type,
                            value: this.editingItem.value
                        },
                    });
                    if (!r.error) {
                        this.getList();
                        this.closeEdit();
                    }
                } catch (e) {
                    this.$error(e.message);
                }
            }
            this.dialogEdit = false;
        },
        async sortUp (item) {
            const parent = item.parent;
            const id = parseInt(item.id);
            const sort = item.sort;
            if (item.sort === 1) {
                return;
            }
            let i = 0;
            let itm = '';
            if ((parent === 0) || (parent === null)) {
                let srt = 0;
                let position = -1;
                for (i = 0; i < this.items.length; i++) {
                    if (parseInt(this.items[i].id) === id) {
                        break;
                    }
                    srt = this.items[i].sort;
                    position = i;
                }
                this.items[position].sort = sort;
                this.items[position + 1].sort = srt;
                itm = this.items[position];
                this.items[position] = this.items[position + 1];
                this.items[position + 1] = itm;
                //query to api
                try {
                    itm = this.items[position];
                    if (itm != undefined) {
                        await this.$api('/admin/menu_items', {
                            method: 'PUT',
                            body: {
                                id: itm.id,
                                title: itm.title,
                                parent: itm.parent,
                                sort: itm.sort,
                                type: itm.type,
                                value: itm.value
                            },
                        });
                    }

                    itm = this.items[position + 1];
                    if (itm != undefined) {
                        await this.$api('/admin/menu_items', {
                            method: 'PUT',
                            body: {
                                id: itm.id,
                                title: itm.title,
                                parent: itm.parent,
                                sort: itm.sort,
                                type: itm.type,
                                value: itm.value
                            },
                        });
                    }
                } catch (e) {
                    this.$error(e.message);
                }
            } else {
                let parent_position = -1;
                for (i = 0; i < this.items.length; i++) {
                    if (parseInt(this.items[i].id) === parseInt(parent)) {
                        parent_position = i;
                        break;
                    }
                }
                if (parent_position === -1) {
                    return;
                }
                let srt = 0;
                let position = 0;
                for (i = 0; i < this.items[parent_position].subitems.length; i++) {
                    if (this.items[parent_position].subitems[i].id === id) {
                        break;
                    }
                    srt = this.items[parent_position].subitems[i].sort;
                    position = i;
                }
                this.items[parent_position].subitems[position].sort = sort;
                this.items[parent_position].subitems[position + 1].sort = srt;
                itm = this.items[parent_position].subitems[position];
                this.items[parent_position].subitems[position] = this.items[parent_position].subitems[position + 1];
                this.items[parent_position].subitems[position + 1] = itm;
                //query to api
                try {
                    itm = this.items[parent_position].subitems[position];
                    if (itm != undefined) {
                        await this.$api('/admin/menu_items', {
                            method: 'PUT',
                            body: {
                                id: itm.id,
                                title: itm.title,
                                parent: itm.parent,
                                sort: itm.sort,
                                type: itm.type,
                                value: itm.value
                            },
                        });
                    }

                    itm = this.items[parent_position].subitems[position + 1];
                    if (itm != undefined) {
                        await this.$api('/admin/menu_items', {
                            method: 'PUT',
                            body: {
                                id: itm.id,
                                title: itm.title,
                                parent: itm.parent,
                                sort: itm.sort,
                                type: itm.type,
                                value: itm.value
                            },
                        });
                    }
                } catch (e) {
                    this.$error(e.message);
                }
            }
        },
        async sortDn (item) {
            const parent = item.parent;
            const id = parseInt(item.id);
            const sort = item.sort;
            let i = 0;
            let itm = '';
            if ((parent === 0) || (parent === null)) {
                if (sort >= this.items.length) {
                    return;
                }
                let srt = 0;
                let position = -1;
                for (i = 0; i < this.items.length; i++) {
                    position = i;
                    if (parseInt(this.items[i].id) === id) {
                        break;
                    }
                }
                srt = this.items[position + 1].sort;
                this.items[position + 1].sort = sort;
                this.items[position].sort = srt;
                itm = this.items[position];
                this.items[position] = this.items[position + 1];
                this.items[position + 1] = itm;
                //query to api
                try {
                    itm = this.items[position];
                    if (itm != undefined) {
                        await this.$api('/admin/menu_items', {
                            method: 'PUT',
                            body: {
                                id: itm.id,
                                title: itm.title,
                                parent: itm.parent,
                                sort: itm.sort,
                                type: itm.type,
                                value: itm.value
                            },
                        });
                    }

                    itm = this.items[position + 1];
                    if (itm != undefined) {
                        await this.$api('/admin/menu_items', {
                            method: 'PUT',
                            body: {
                                id: itm.id,
                                title: itm.title,
                                parent: itm.parent,
                                sort: itm.sort,
                                type: itm.type,
                                value: itm.value
                            },
                        });
                    }
                } catch (e) {
                    this.$error(e.message);
                }
            } else {
                let parent_position = -1;
                for (i = 0; i < this.items.length; i++) {
                    if (parseInt(this.items[i].id) === parseInt(parent)) {
                        parent_position = i;
                        break;
                    }
                }
                if (parent_position === -1) {
                    return;
                }
                if (sort >= this.items[parent_position].subitems.length) {
                    return;
                }

                let srt = 0;
                let position = 0;
                for (i = 0; i < this.items[parent_position].subitems.length; i++) {
                    position = i;
                    if (this.items[parent_position].subitems[i].id === id) {
                        break;
                    }
                }
                srt = this.items[parent_position].subitems[position + 1].sort;
                this.items[parent_position].subitems[position + 1].sort = sort;
                this.items[parent_position].subitems[position].sort = srt;
                itm = this.items[parent_position].subitems[position];
                this.items[parent_position].subitems[position] = this.items[parent_position].subitems[position + 1];
                this.items[parent_position].subitems[position + 1] = itm;
                //query to api
                try {
                    itm = this.items[parent_position].subitems[position];
                    if (itm != undefined) {
                        await this.$api('/admin/menu_items', {
                            method: 'PUT',
                            body: {
                                id: itm.id,
                                title: itm.title,
                                parent: itm.parent,
                                sort: itm.sort,
                                type: itm.type,
                                value: itm.value
                            },
                        });
                    }

                    itm = this.items[parent_position].subitems[position + 1];
                    if (itm != undefined) {
                        await this.$api('/admin/menu_items', {
                            method: 'PUT',
                            body: {
                                id: itm.id,
                                title: itm.title,
                                parent: itm.parent,
                                sort: itm.sort,
                                type: itm.type,
                                value: itm.value
                            },
                        });
                    }
                } catch (e) {
                    this.$error(e.message);
                }
            }
        },
        getMaxSort () {
            let v = 0;
            for(let i = 0; i < this.items.length; i++) {
                if (this.items[i].sort > v) {
                    v = this.items[i].sort;
                }
            }
            return v;
        },
        openAll () {
            for (let i = 0; i < this.items.length; i++) {
                this.items[i].opened = true;
            }
        },
        closeAll () {
            for (let i = 0; i < this.items.length; i++) {
                this.items[i].opened = false;
            }
        },
        showError() {
            this.$error(String(Date.now()));
        },
    },
};
</script>

<style lang="scss" scoped>
    .mcard {

        &__actions_row {
            margin: 0 0 0 10px;
            padding: 10px 10px 10px 0;
        }

        &__action_button {
            margin-right: 10px;
        }

        &__rows {
            display: flex;
            flex-direction: column;
        }

        &__row {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
        }

        &__row_actions {
            background: transparent !important;
            min-width: 166px;
            display: flex;
            flex-direction: row;
            justify-content: flex-end;

            .v-btn {
                padding: 0 3px;
                min-width: 30px;
            }
        }

        &__row_unpack {
            background: transparent !important;
            min-width: 58px;
            max-width: 58px;

            .v-btn {
                padding: 0 3px;
                min-width: 30px;
            }
        }

        &__row:hover {
            background: #eee;
        }

        &__row_title {
            line-height: 28px;
            min-width: 35vw;
            max-width: 35vw;
        }

        &__row_kind {
            line-height: 44px;
            min-width: 10vw;
            max-width: 10vw;
            font-size: 14px;
            color: #999;
        }

        &__row_value {
            line-height: 44px;
            min-width: 15vw;
            max-width: 15vw;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
    }

    .hidden_row {
        display: none;
    }
</style>